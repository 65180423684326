<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Reminders
                        <v-spacer />
                        <v-btn @click="sendReminders()" color="primary" rounded class="px-5 mr-3">
                            Send
                        </v-btn>
                        <v-btn @click="openCreateReminderDialog()" color="primary" rounded class="px-5">
                            Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-skeleton-loader v-if="loading"></v-skeleton-loader>
                        <v-data-table v-else :headers="headers" :items="reminders">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.subject }}</td>
                                    <td>{{ item.days_before }}</td>
                                    <td>{{ item.date_type ? item.date_type.name : null }}</td>
                                    <td>{{ item.recipient_type ? item.recipient_type.name : null }}</td>
                                    <td>{{ item.delivery_type ? item.delivery_type.name : null }}</td>
                                    <td>
                                        <v-btn @click="openUpdateReminderDialog(item)" icon>
                                            <v-icon>mdi-pen</v-icon>
                                        </v-btn>
                                        <v-btn @click="openDeleteReminderDialog(item)" icon>
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog :value="create_reminder_dialog" persistent width="600">
            <v-card>
                <v-card-title>
                    Create Reminder
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="new_reminder.name" label="Name *"></v-text-field>
                            <v-text-field v-model="new_reminder.from_email" label="From Email *"></v-text-field>
                            <v-text-field v-model="new_reminder.days_before" label="Days Before *"
                                type="number"></v-text-field>
                            <v-select :items="recipient_types.filter(x => x.id == 3)" v-model="new_reminder.recipient_type_id" item-text="name"
                                item-value="id" label="Recipient Type *"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="new_reminder.subject" label="Subject *"></v-text-field>
                            <v-text-field v-model="new_reminder.from_name" label="From Name *"></v-text-field>
                            <v-select :items="date_types" v-model="new_reminder.date_type_id" item-text="name"
                                item-value="id" label="Date Type *"></v-select>
                            <v-select :items="delivery_types.filter(x => x.id == 1)" v-model="new_reminder.delivery_type_id" item-text="name"
                                item-value="id" label="Delivery Type *"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <h4>Email Content</h4>
                            <wysiwyg v-model="new_reminder.body" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeCreateReminderDialog()" text rounded class="px-5">
                        Cancel
                    </v-btn>
                    <v-btn @click="createReminder()" color="primary" rounded class="px-5">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="update_reminder_dialog" persistent width="600">
            <v-card v-if="selected_reminder">
                <v-card-title>
                    Update Reminder
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="selected_reminder.name" label="Name *"></v-text-field>
                            <v-text-field v-model="selected_reminder.from_email" label="From Email *"></v-text-field>
                            <v-text-field v-model="selected_reminder.days_before" label="Days Before *"
                                type="number"></v-text-field>
                            <v-select :items="recipient_types.filter(x => x.id == 3)" v-model="selected_reminder.recipient_type_id"
                                item-text="name" item-value="id" label="Recipient Type *"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="selected_reminder.subject" label="Subject *"></v-text-field>
                            <v-text-field v-model="selected_reminder.from_name" label="From Name *"></v-text-field>
                            <v-select :items="date_types" v-model="selected_reminder.date_type_id" item-text="name"
                                item-value="id" label="Date Type *"></v-select>
                            <v-select :items="delivery_types.filter(x => x.id == 1)" v-model="selected_reminder.delivery_type_id"
                                item-text="name" item-value="id" label="Delivery Type *"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <h4>Email Content</h4>
                            <wysiwyg v-model="selected_reminder.body" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeUpdateReminderDialog()" text rounded class="px-5">
                        Cancel
                    </v-btn>
                    <v-btn @click="updateReminder()" color="primary" rounded class="px-5">
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="delete_reminder_dialog" persistent width="600">
            <v-card>
                <v-card-title>
                    Are you sure?
                </v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeDeleteReminderDialog()" text rounded class="px-5">
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteReminder()" color="red" dark rounded class="px-5">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Reminders',
    data: () => ({
        loading: true,
        reminders: [],
        recipient_types: [],
        delivery_types: [],
        date_types: [],
        headers: [
            { text: 'Name' },
            { text: 'Subject' },
            { text: 'Days Before' },
            { text: 'Date Type' },
            { text: 'Recipient Type' },
            { text: 'Delivery Type' },
            { text: '' },
        ],
        new_reminder: {
            name: null,
            subject: null,
            body: null,
            from_email: null,
            from_name: null,
            days_before: null,
            recipient_type_id: null,
            delivery_type_id: null,
            date_type_id: null,
        },
        create_reminder_dialog: false,
        selected_reminder: null,
        update_reminder_dialog: false,
        delete_reminder_dialog: false,
    }),
    async mounted() {
        this.getReminders()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
    },
    methods: {
        async sendReminders() {
            let payload = {
                company: this.get_selected_company,
            }
            await this.$axios.post('v2/reminders/send_reminders', payload)
                .then(({ data }) => {
                    console.log(data)
                })
        },
        async getReminders() {
            await this.$axios.get('v2/reminders/get_reminders')
                .then(({ data }) => {
                    if (data.response) {
                        this.reminders = data.data
                        this.recipient_types = data.recipient_types
                        this.delivery_types = data.delivery_types
                        this.date_types = data.date_types
                        this.loading = false
                    }
                })
        },
        openCreateReminderDialog() {
            this.create_reminder_dialog = true
        },
        closeCreateReminderDialog() {
            this.create_reminder_dialog = false
            this.new_reminder = {
                name: null,
                subject: null,
                body: null,
                from_email: null,
                from_name: null,
                days_before: null,
                recipient_type_id: null,
                delivery_type_id: null,
                date_type_id: null,
            }
        },
        async createReminder() {
            await this.$axios.post('v2/reminders/create_reminder', this.new_reminder)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateReminderDialog()
                        this.getReminders()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateReminderDialog(item) {
            this.update_reminder_dialog = true
            this.selected_reminder = item
        },
        closeUpdateReminderDialog() {
            this.update_reminder_dialog = false
            this.selected_reminder = null
        },
        async updateReminder() {
            await this.$axios.post(`v2/reminders/update_reminder/${this.selected_reminder.id}`, this.selected_reminder)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateReminderDialog()
                        this.getReminders()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteReminderDialog(item) {
            this.delete_reminder_dialog = true
            this.selected_reminder = item
        },
        closeDeleteReminderDialog() {
            this.delete_reminder_dialog = false
            this.selected_reminder = null
        },
        async deleteReminder() {
            await this.$axios.delete(`v2/reminders/delete_reminder/${this.selected_reminder.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteReminderDialog()
                        this.getReminders()
                    }
                })
        },
    }
}
</script>