<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-row class="mt-5" justify="center" align="center">
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Media</strong></small>
                    <v-spacer />
                    <!-- <v-btn color="#588BAD" rounded small class="pl-10 pr-10" dark
                        style="text-transform: none !important; color: white;" @click="add_media_dialog_fn">
                        Add media
                    </v-btn> -->
                </v-card-title>
                <v-card-subtitle>
                    Add images to use them in your itinerary
                </v-card-subtitle>
                <v-card-text>
                    <v-file-input v-model="file" @change="uploadImage()" label="Upload New Image"></v-file-input>
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-img :src="get_supplier_setup.image" eager cover style="width:96%;height:210px;" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
        <!-- <MediaUpload :value="add_media_dialog" @close_dialog="close_dialog" /> -->
    </v-container>
</template>

<script>
// import MediaUpload from './inputs/MediaUpload.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        // MediaUpload
    },
    props: [
    ],
    data: () => ({
        file: null,
        add_media_dialog: false,
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_images: 'supplier/get_selected_images',
            get_supplier_setup: 'supplier/get_supplier_setup',
            get_selected_company: 'auth/get_selected_company'
        })
    },
    methods: {
        add_media_dialog_fn() {
            this.add_media_dialog = true
        },
        close_dialog() {
            this.add_media_dialog = false
        },
        async uploadImage() {
            let formData = new FormData()
            formData.append('file', this.file)
            await this.$axios.post(`v2/suppliers/upload_supplier_image/${this.get_supplier_setup.id}`, formData)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$store.dispatch('supplier/set_supplier_setup', data.data)
                    }
                })
        },
        async remove_selected_image() {
            this.$store.dispatch('supplier/set_selected_images', {})
            await this.$axios.patch('/suppliers/update_media', {
                media: null,
                supplier_id: this.get_supplier_setup.id,
                company_id: this.get_selected_company.id
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                        this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>