<template>
    <v-container fluid style="background-color: #f4f4f5 !important; height: 100%" class="pa-0 ma-0">
        <div class="header">
            <strong class="white--text">
                {{ get_supplier_setup.supplier_name }}
            </strong>
        </div>
        <v-col cols="12" class="ma-0 pa-0">
            <v-tabs centered dark background-color="#588BAD" v-model="tab">
                <v-tab href="#tab-1" class="tabs">Setup</v-tab>
                <v-tab href="#tab-2" class="tabs">Description</v-tab>
                <v-tab href="#tab-3" class="tabs">Media</v-tab>
                <!-- <v-tab href="#tab-4" class="tabs">Amenities</v-tab> -->
                <!-- <v-tab href="#tab-5" class="tabs">Pricing</v-tab> -->
                <v-tab href="#tab-6" class="tabs">Products</v-tab>
                <!-- <v-tab href="#tab-7" class="tabs">Rates</v-tab> -->
                <v-tab href="#tab-8" class="tabs">Who Used</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <Setup />
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-2">
                    <Description />
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-3">
                    <Media />
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-4">
                    <Amenities />
                </v-tab-item>
            </v-tabs-items>
            <!-- <v-tabs-items v-model="tab">
        <v-tab-item value="tab-5">
          <Pricing />
        </v-tab-item>
      </v-tabs-items> -->
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-6">
                    <Product />
                </v-tab-item>
            </v-tabs-items>
            <!-- <v-tabs-items v-model="tab">
                <v-tab-item value="tab-7">
                    <Rate />
                </v-tab-item>
            </v-tabs-items> -->
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-8">
                    <WhoUsed />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Setup from './pages/SetupComponent.vue'
import Description from './pages/DescriptionComponent.vue'
import Media from './pages/MediaComponent.vue'
import Amenities from './pages/AmenitiesComponent.vue'
// import Pricing from './pages/PricingComponent.vue'
import Product from './pages/ProductComponent.vue'
// import Rate from './pages/RateComponent.vue'
import WhoUsed from './pages/WhoUsedComponent.vue'

export default {
    components: {
        Setup,
        Description,
        Media,
        Amenities,
        // Pricing,
        Product,
        // Rate,
        WhoUsed,
    },
    props: [],
    data: () => ({
        tab: null,
    }),
    mounted() { },
    created() { },
    computed: {
        ...mapGetters({
            get_supplier_setup: 'supplier/get_supplier_setup',
        }),
    },
    methods: {},
    watch: {},
};
</script>

<style scoped lang="scss">
.header {
    max-width: 100%;
    background-color: #79a2bd;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 50px;
}

.tabs {
    text-transform: none !important;
}
</style>
