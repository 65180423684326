<template>
    <v-container fluid>
        <v-row class="mt-5" justify="center" align="center">
            <v-card elevation="0">
                <v-card-title style="text-align:center;">
                    Thank you for confirming that you have received payment.
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            booking_reference_number: null,
            booking_ids: [],
            payment_status_id: 0,
        }
    },
    mounted() {
        this.getData()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getData() {
            this.booking_ids = this.$router.currentRoute.params.booking_ids.split(',')
            if (this.$router.currentRoute.name == 'Confirm Payment') {
                this.payment_status_id = 3
                console.log(this.payment_status_id)
                this.updateBookingPaymentStatuses()
            }
        },
        async updateBookingPaymentStatuses() {
            let payload = {
                booking_ids: this.booking_ids,
                booking_reference_number: this.booking_reference_number,
                payment_status_id: this.payment_status_id,
            }
            console.log(payload)
            await this.$axios.post('v2/itineraries/supplier_update_booking_payment', payload)
                .then(({ data }) => {
                    console.log(data)
                    this.$toast.success(data.message)
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>