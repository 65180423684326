<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-card v-else>
            <v-card-title>
                <strong>Users</strong>
                <v-spacer></v-spacer>
                <v-btn @click="openCreateUserDialog()" color="#525A68" class="pl-10 pr-10" dark rounded
                    :disabled="get_user.user_role_id === 1 ? false : true">
                    Add
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="users" disable-pagination hide-default-footer>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.email }}
                            </td>
                            <td>
                                <div style="display:flex;align-items:center;">
                                    <label v-for="(access, index) in item.accesses" :key="index" class="access-label">
                                        {{ access.company.company_name }}
                                        <v-btn icon small @click="openUpdateAccessDialog(access)"
                                            v-if="item.user_role_id > 2">
                                            <v-icon small>mdi-pen</v-icon>
                                        </v-btn>
                                        <v-btn icon small @click="openDeleteAccessDialog(access)">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                    </label>
                                    <v-btn icon small @click="openCreateAccessDialog(item)">
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                            <td>
                                {{ $date(item.created_at).format('MMM DD, YYYY') }}
                            </td>
                            <td>
                                <v-btn @click="openUpdateUserDialog(item)" v-if="get_user.user_role_id === 1" icon>
                                    <v-icon>
                                        mdi-pen
                                    </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog :value="create_user_dialog" max-width="620" persistent>
                <v-card>
                    <v-card-title>Create User</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="new_user.name" label="Name" dense outlined />
                        <v-text-field v-model="new_user.email" label="Email" dense outlined />
                        <v-text-field v-model="new_user.password" label="Password" dense outlined />
                        <v-autocomplete v-model="new_user.user_role_id"
                            :items="[{ id: 1, name: 'Admin' }, { id: 2, name: 'User' }]" item-text="name"
                            item-value="id" label="Role" chips outlined dense>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn @click="closeCreateUserDialog()" text rounded>Cancel</v-btn>
                        <v-btn @click="createUser()" color="primary" rounded>
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_user_dialog" max-width="600" persistent>
                <v-card v-if="selected_user">
                    <v-card-title>Update User</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="selected_user.name" label="Name" outlined dense />
                        <v-text-field v-model="selected_user.email" label="Email" outlined dense />
                        <v-text-field v-model="selected_user.password" label="Password" dense outlined />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn @click="closeUpdateUserDialog()" text rounded>Cancel</v-btn>
                        <v-btn @click="updateUser()" color="primary" rounded>
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="create_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add Access</v-card-title>
                    <v-card-text>
                        <v-select :items="allowed_companies" v-model="access_to_create" label="Company Access *"
                            item-text="company_name" item-value="id" multiple outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeCreateAccessDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="createAccess()">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to remove access to this company?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="custom-buttons" text @click="closeDeleteAccessDialog()">Cancel</v-btn>
                        <v-btn class="custom-buttons" dark @click="deleteAccess()">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: true,
            users: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Access', sortable: false },
                { text: 'Created', value: 'created_at' },
                { text: 'Action(s)', sortable: false }
            ],
            create_user_dialog: false,
            new_user: {
                name: null,
                email: null,
                password: null,
                user_role_id: null,
            },
            update_user_dialog: false,
            selected_user: null,
            create_access_dialog: false,
            allowed_companies: [],
            access_to_create: null,
            delete_access_dialog: false,
            access_to_delete: null,
            delete_user_dialog: false,
            selected_access: null,
        }
    },
    async mounted() {
        this.getUsers()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_companies: 'itineraryV2/get_companies',
        })
    },
    methods: {
        async getUsers() {
            await this.$axios.get('v2/users/get_users')
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.users = data.data
                        this.loading = false
                    }
                })
        },
        openCreateUserDialog() {
            this.create_user_dialog = true
            this.new_user.password = Math.random().toString(36).slice(2)
        },
        closeCreateUserDialog() {
            this.create_user_dialog = false
            this.new_user = {
                name: null,
                email: null,
                password: null,
                user_role_id: null,
            }
        },
        async createUser() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.new_user,
            }
            console.log(payload)
            await this.$axios.post('v2/users/create_user', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateUserDialog(item) {
            this.update_user_dialog = true
            this.selected_user = item
        },
        closeUpdateUserDialog() {
            this.update_user_dialog = false
            this.selected_user = null
        },
        async updateUser() {
            let payload = {
                ...this.selected_user,
            }
            await this.$axios.post(`v2/users/update_user/${this.selected_user.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateUserDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateAccessDialog(item) {
            this.selected_user = item
            this.create_access_dialog = true
            this.allowed_companies = Object.assign([], this.get_companies)
            if (item.accesses) {
                item.accesses.forEach(access => {
                    this.get_companies.forEach((company, index) => {
                        if (access.company_id == company.id) {
                            this.allowed_companies.splice(index, 1)
                        }
                    })
                })
            }
        },
        closeCreateAccessDialog() {
            this.create_access_dialog = false
            this.selected_user = null
            this.allowed_companies = []
            this.access_to_create = null
        },
        async createAccess() {
            let payload = {
                access: this.access_to_create,
                user_id: this.selected_user.id,
            }
            await this.$axios.post('v2/users/create_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteAccessDialog(access) {
            this.delete_access_dialog = true
            this.access_to_delete = access
        },
        closeDeleteAccessDialog() {
            this.delete_access_dialog = false
            this.access_to_delete = null
        },
        async deleteAccess() {
            await this.$axios.delete(`v2/users/delete_user_access/${this.access_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        }
    },

}
</script>

<style>
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>