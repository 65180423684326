<template>
    <v-container>
        <v-dialog v-model="create_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>Add Traveller</v-card-title>
                <v-card-text>
                    <v-text-field v-model="new_traveller.firstname" label="First Name *" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.lastname" label="Last Name *" outlined></v-text-field>
                    <v-text-field v-model="new_traveller.email" label="Email *" outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="createTraveller()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="delete_traveller_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to remove this traveller?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteTravellerDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteTraveller()" color="red" dark>Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'CreateTravellerDialog',
    components: {
    },
    props: [
        'create_traveller_dialog', 'delete_traveller_dialog', 'itinerary', 'selected_traveller'
    ],
    data: () => ({
        new_traveller: {
            firstname: null,
            lastname: null,
            email: null,
        },
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        closeCreateTravellerDialog() {
            this.$emit('closeCreateTravellerDialog')
            this.new_traveller = {
                firstname: null,
                lastname: null,
                email: null,
            }
        },
        async createTraveller() {
            if (this.new_traveller.firstname == null || this.new_traveller.lastname == null || this.new_traveller.email == null) {
                this.$toast.error('Please fill out all details')
                return
            }
            let payload = {
                itinerary_id: this.itinerary.id,
                travellers_info: JSON.stringify(this.new_traveller),
                contact_id: 0,
            }
            console.log(payload)
            await this.$axios.post('v2/itineraries/create_itinerary_traveller', payload)
                .then(({ data }) => {
                    this.$toast.success(data.message)
                    this.closeCreateTravellerDialog()
                    this.$emit('refreshItinerary')
                })
        },
        closeDeleteTravellerDialog() {
            this.$emit('closeDeleteTravellerDialog')
        },
        async deleteTraveller() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_traveller/${this.selected_traveller.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteTravellerDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
    },
}
</script>