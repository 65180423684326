import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/login/routes/index.js'
import DashboardRoutes from '../components/dashboard/routes/index.js'
import UserRoutes from '../components/users/routes/index.js'
import CompanyRoutes from '../components/companies/routes/index.js'
import SupplierRoutes from '../components/suppliers/router/index.js'
import ContactRoutes from '../components/contacts/router/index.js'
import TaskRoutes from '../components/task/router/index.js'
import AgenciesRoutes from '../components/agencies/router/index.js'
import ItineraryRoutes from '../components/itinerary/router/index.js'
import ResourceRoutes from '../components/resources/router/index.js'
import EmailRoutes from '../components/emails/router/index.js'
import ReminderRoutes from '../components/reminders/routes/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        ...Login,
        ...DashboardRoutes,
        ...UserRoutes,
        ...CompanyRoutes,
        ...SupplierRoutes,
        ...ContactRoutes,
        ...TaskRoutes,
        ...AgenciesRoutes,
        ...ItineraryRoutes,
        ...ResourceRoutes,
        ...EmailRoutes,
        ...ReminderRoutes,
    ]
})
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = 'PMT – ' + to.name ?? 'PMT'
    });
})
export default router