export default {
    namespaced: true,
    state: {
        companies: [],
        statuses: [],
        templates: [],
        tour_types: [],
        invoice_statuses: [],
        stages: [],
        supplier_types: [],
        product_types: [],
        booking_statuses: [],
        booking_payment_statuses: [],
    },
    mutations: {
        set_statuses(state, payload) {
            state.statuses = [...payload]
        },
        set_companies(state, payload) {
            state.companies = [...payload]
        },
        set_templates(state, payload) {
            state.templates = [...payload]
        },
        set_tour_types(state, payload) {
            state.tour_types = [...payload]
        },
        set_invoice_statuses(state, payload) {
            state.invoice_statuses = [...payload]
        },
        set_stages(state, payload) {
            state.stages = [...payload]
        },
        set_supplier_types(state, payload) {
            state.supplier_types = [...payload]
        },
        set_product_types(state, payload) {
            state.product_types = [...payload]
        },
        set_booking_statuses(state, payload) {
            state.booking_statuses = [...payload]
        },
        set_booking_payment_statuses(state, payload) {
            state.booking_payment_statuses = [...payload]
        },
    },
    getters: {
        get_companies: state => state.companies,
        get_statuses: state => state.statuses,
        get_templates: state => state.templates,
        get_tour_types: state => state.tour_types,
        get_invoice_statuses: state => state.invoice_statuses,
        get_stages: state => state.stages,
        get_supplier_types: state => state.supplier_types,
        get_product_types: state => state.product_types,
        get_booking_statuses: state => state.booking_statuses,
        get_booking_payment_statuses: state => state.booking_payment_statuses,
    },
    actions: {
        set_companies({ commit }, payload) {
            commit('set_companies', payload)
        },
        set_statuses({ commit }, payload) {
            commit('set_statuses', payload)
        },
        set_templates({ commit }, payload) {
            commit('set_templates', payload)
        },
        set_tour_types({ commit }, payload) {
            commit('set_tour_types', payload)
        },
        set_invoice_statuses({ commit }, payload) {
            commit('set_invoice_statuses', payload)
        },
        set_stages({ commit }, payload) {
            commit('set_stages', payload)
        },
        set_supplier_types({ commit }, payload) {
            commit('set_supplier_types', payload)
        },
        set_product_types({ commit }, payload) {
            commit('set_product_types', payload)
        },
        set_booking_statuses({ commit }, payload) {
            commit('set_booking_statuses', payload)
        },
        set_booking_payment_statuses({ commit }, payload) {
            commit('set_booking_payment_statuses', payload)
        },
    }
}