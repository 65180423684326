<template>
    <v-container>
        <v-dialog v-model="create_contributor_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>Add Contributor</v-card-title>
                <v-card-text>
                    <v-autocomplete v-model="selected_user" :items="users" item-value="id" item-text="email"
                        label="Search" dense outlined return-object>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateContributorDialog()" text>Cancel</v-btn>
                    <v-btn @click="createContributor()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="delete_contributor_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to remove this contributor?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteContributorDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteContributor()" color="red" dark>Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'CreateContributorDialog',
    components: {
    },
    props: [
        'create_contributor_dialog', 'delete_contributor_dialog', 'itinerary', 'users', 'selected_contributor'
    ],
    data: () => ({
        selected_user: null,
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        closeCreateContributorDialog() {
            this.$emit('closeCreateContributorDialog')
            this.selected_user = null
        },
        async createContributor() {
            if (this.selected_user == null) {
                this.$toast.error('Please select a user')
                return
            }
            let payload = {
                itinerary_id: this.itinerary.id,
                user_id: this.selected_user.id,
            }
            console.log(payload)
            await this.$axios.post('v2/itineraries/create_itinerary_contributor', payload)
                .then(({ data }) => {
                    this.$toast.success(data.message)
                    this.closeCreateContributorDialog()
                    this.$emit('refreshItinerary')
                })
        },
        closeDeleteContributorDialog() {
            this.$emit('closeDeleteContributorDialog')
        },
        async deleteContributor() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_contributor/${this.selected_contributor.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteContributorDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
    },
}
</script>