import ItineraryListComponent from '../ItineraryListComponent.vue'
import BookingListComponent from '../BookingListComponent.vue'
import ItineraryComponent from '../components/ItineraryComponent.vue'
import PublicPreviewComponent from '../components/preview/PublicPreviewComponent.vue'

export default [
    {
        path: '/itineraries',
        name: 'Itineraries',
        component: ItineraryListComponent,
    },
    {
        path: '/bookings',
        name: 'Bookings',
        component: BookingListComponent,
    },
    {
        path: '/itinerary/update/:id',
        name: 'Itinerary',
        component: ItineraryComponent,
    },
    {
        path: '/public_itinerary/:id',
        name: 'View Itinerary',
        component: PublicPreviewComponent,
        meta: { layout: 'blank' }
    },
    {
        path: '/preview_itinerary/:id',
        name: 'Preview Itinerary',
        component: ItineraryComponent,
        meta: { layout: 'blank' }
    },
]