<template>
    <v-dialog :value="delete_bookings_dialog" persistent max-width="50%">
        <v-card>
            <v-card-title>
                Are you sure?
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="deleteBookings()" color="primary" rounded class="px-5">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'delete_bookings_dialog'
    ],
    methods: {
        close() {
            this.$emit('close')
        },
        deleteBookings() {
            this.$emit('deleteBookings')
        }
    },
}
</script>