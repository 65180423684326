<template>
    <v-container fluid style="background-color: #f4f4f5 !important">
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row class="mt-5" justify="center" align="center" v-else>
            <v-card width="820" elevation="2">
                <v-card-title class="text-small">
                    <small><strong>Products</strong></small>
                    <v-spacer />
                    <v-btn color="#588BAD" rounded small class="pl-10 pr-10" dark
                        style="text-transform: none !important; color: white"
                        @click="$router.push({ name: '/supplier-setup/create-product' })">
                        Create Product
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <!-- {{get_supplier_amenities}} -->
                    <!-- {{ get_supplier_setup }} -->
                    <!-- {{ get_supplier_product }} -->
                    <v-checkbox v-model="show_archived" label="Show Archived"></v-checkbox>
                    <v-list>
                        <v-list-item-group v-for="(items, index) in get_supplier_product" :key="index">
                            <v-list-item v-if="items.is_archived == 0 || show_archived" two-line style="
                  border-top: 1px solid gray;
                  border-bottom: 1px solid gray;
                " class="mt-2" @click="click(items)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <label style="font-size: 16px">{{ items.name }}</label>
                                        <label v-if="items.is_archived" style="font-size:14px;color:grey;">
                                            (archived)</label>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list v-if="items.is_archived == 0">
                                            <v-list-item>
                                                <v-list-item-title @click="duplicate_item(items)"> Duplicate
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title @click="archive_item(items)"> Archive
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        <v-list v-else>
                                            <v-list-item>
                                                <v-list-item-title @click="restore_item(items)"> Restore
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {},
    props: [],

    destroyed() {
        this.archive_switch = false;
        this.$store.dispatch('supplier/set_archive_switch', false);
    },

    data: () => ({
        loading: true,
        amenities: null,
        archive_switch: false,
        show_archived: false,
    }),
    async mounted() {
        await this.$store.dispatch("supplier/fetch_supplier_product", this.get_supplier_setup.id)
            .then(() => {
                this.loading = false
            });
    },
    created() { },
    computed: {
        ...mapGetters({
            get_supplier_setup: "supplier/get_supplier_setup",
            get_supplier_product: "supplier/get_supplier_product",
            get_archived_supplier_product: "supplier/get_archived_supplier_product"
        }),
    },
    methods: {
        click(e) {
            this.$store.dispatch("supplier/set_supplier_product_setup", e);
            this.$router.push({ name: "/supplier-setup/product/setup" });
        },
        async duplicate_item(item) {
            console.log(item)
            await this.$store.dispatch('supplier/duplicate_product', item)
            await this.$store.dispatch("supplier/fetch_supplier_product", this.get_supplier_setup.id)
        },
        async archive_item(e) {
            const toPass = {
                item: e,
                supplier_id: this.get_supplier_setup.id
            }
            this.$store.dispatch('supplier/set_supplier_product_archive', toPass);
        },
        switch_archive() {
            this.archive_switch = !this.archive_switch;

            this.$store.dispatch('supplier/set_archive_switch', this.archive_switch);
        },

        restore_item(e) {
            const toPass = {
                item: e,
                supplier_id: this.get_supplier_setup.id
            };

            this.$store.dispatch('supplier/set_supplier_product_restore', toPass);
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss"></style>
