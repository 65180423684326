<template>
    <v-dialog :value="custom_rate_dialog" persistent max-width="600">
        <v-card v-if="selected_rate">
            <v-card-title>
                Edit Rate
            </v-card-title>
            <v-card-title>
                <small v-if="selected_rate.itinerary_id == null">Currently using: <b>supplier rate</b></small>
                <small v-else>Currently using: <b>custom rate</b></small>
                <v-spacer />
                <v-btn v-if="selected_rate.itinerary_id != null" @click="deleteCustomRate()" small text
                    color="red">Revert to supplier rate</v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="updated_rate.net" @keyup="calculateGrossCommission()" label="Net" outlined dense
                    type="number">
                </v-text-field>
                <v-text-field v-model="updated_rate.markup" @keyup="calculateGrossCommission()" label="Markup %"
                    outlined dense type="number">
                </v-text-field>
                <v-text-field v-model="updated_rate.gross" label="Gross" outlined dense type="number">
                </v-text-field>
                <v-text-field v-model="updated_rate.commission" label="Commission" outlined dense disabled
                    type="number">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn v-if="selected_rate.itinerary_id == null" @click="saveCustomRate()" color="primary" rounded
                    class="px-5">
                    Save as Custom Rate
                </v-btn>
                <v-btn v-else @click="saveCustomRate()" color="primary" rounded
                    class="px-5">
                    Update Custom Rate
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'custom_rate_dialog', 'selected_rate'
    ],
    data() {
        return {
            updated_rate: { ...this.selected_rate }
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        close() {
            this.$emit('close')
        },
        saveCustomRate() {
            this.$emit('saveCustomRate', this.updated_rate)
        },
        deleteCustomRate() {
            this.$emit('deleteCustomRate', this.updated_rate)
        },
        calculateGrossCommission() {
            let gross = Number(this.updated_rate.net) + Number(this.updated_rate.net * (this.updated_rate.markup / 100))
            this.updated_rate.gross = Number(gross)
            this.updated_rate.commission = Number(this.updated_rate.markup / gross)
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>