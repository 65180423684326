import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './data/vuex'
import axios from './plugins/axios.js'
import { VueEditor } from 'vue2-editor'
import Vue2Filters from 'vue2-filters'
import numeral from 'numeral'
import VueDayjs from 'vue-dayjs-plugin'
import VueExcelXlsx from 'vue-excel-xlsx'
import JsonCSV from 'vue-json-csv'
import wysiwyg from 'vue-wysiwyg'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import Default from './layouts/Default.vue'
import Blank from './layouts/Blank.vue'
import Input from './layouts/Input.vue'

Vue.component('default-layout', Default)
Vue.component('blank-layout', Blank)
Vue.component('input-layout', Input)
Vue.config.productionTip = false

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0.00')
});

Vue.use(axios)
Vue.use(VueEditor)
Vue.use(Vue2Filters)
Vue.use(VueDayjs)
Vue.use(VueExcelXlsx)
Vue.use(wysiwyg, {})
Vue.component('downloadCsv', JsonCSV)
Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_LEFT
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
