<template>
    <v-dialog :value="supplier_email_dialog" persistent max-width="700px">
        <v-card v-if="grouped_bookings">
            <v-card-title>
                <label v-if="supplier_email_type == 'booking'">
                    Send Booking Requests
                </label>
                <label v-else-if="supplier_email_type == 'cancellation'">
                    Send Cancellation Requests
                </label>
                <v-spacer />

                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-card v-for="(supplier_bookings, index) in updated_bookings" :key="index">
                    <v-list class="mb-5" dense two-line>
                        <v-list-group>
                            <template v-slot:activator>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <b>
                                                    {{ supplier_bookings[0].option.product.supplier.supplier_name }}
                                                </b>
                                                <v-text-field
                                                    v-model="supplier_bookings[0].option.product.supplier.email"
                                                    label="Supplier Email" @click.stop class="ml-5">
                                                </v-text-field>
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div v-for="(booking, booking_index) in supplier_bookings"
                                                :key="'booking' + booking_index" class="my-3">
                                                <div class="ml-3">{{ booking.option.product.name }}</div>
                                                <div class="ml-3">
                                                    <b>x{{ booking.count }}</b>
                                                    {{ booking.option.name }}
                                                </div>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list-item>
                                <wysiwyg v-model="supplier_bookings[0].email" />
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="sendSupplierEmails()" color="primary" rounded class="px-5">
                    Send {{ updated_bookings.length }} email<label v-if="updated_bookings.length > 1">s</label>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'supplier_email_dialog', 'supplier_email_type', 'grouped_bookings'
    ],
    data() {
        return {
            updated_bookings: { ...this.grouped_bookings },
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        close() {
            this.$emit('close')
        },
        sendSupplierEmails() {
            this.$emit('sendSupplierEmails', this.updated_bookings)
        },
    },
}
</script>

<style scoped lang="scss"></style>