<template>
    <v-container fluid class="px-0 pb-0">
        <v-row v-for="(day, day_index) in days" :key="'day' + day_index" no-gutters
            :style="day_index > 0 ? 'page-break-before: always;margin:0px 40px;' : 'margin:0px 40px;'">
            <v-col v-if="day.bookings.length > 0" cols="12"
                :style="'margin-top:30px;border-left:1px solid ' + itinerary.company.primary_color + ';border-right:1px solid ' + itinerary.company.primary_color + ';border-bottom:1px solid ' + itinerary.company.primary_color + ';'"
                class="pb-6">
                <v-row v-for="(booking, booking_index) in day.bookings" :key="'booking' + booking_index" no-gutters
                    style="page-break-inside: avoid;">
                    <v-col cols="12">
                        <v-row v-if="booking_index == 0" no-gutters
                            :style="'padding:16px 30px;font-weight:700;background-color:' + itinerary.company.primary_color"
                            class="lora">
                            <v-col :cols="12">
                                <label style="font-size:22px;color:white;">
                                    Day {{ day_index + 1 }}
                                </label>
                                <label style="font-size:18px;margin-left:20px;color:white;">
                                    {{ $date(day.date).format('MMMM DD (dddd)') }}
                                </label>
                                <label v-if="day_index == 0"
                                    style="font-size:18px;margin-left:20px;color:white;">ARRIVAL</label>
                                <label v-if="day_index == days.length - 1"
                                    style="font-size:18px;margin-left:20px;color:white;">DEPARTURE</label>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="px-7 pt-5 inter">
                            <v-col cols="12">
                                <v-icon
                                    v-if="booking.option.product.supplier.id != 3487 && booking.option.product.supplier.id != 3488"
                                    class="mr-2 mb-2" :color="itinerary.company.tertiary_color">
                                    {{ booking.option.product.type.icon }}
                                </v-icon>
                                <label
                                    v-if="booking.option.product.supplier.id != 3487 && booking.option.product.supplier.id != 3488"
                                    style="font-weight:600;font-size:22px;" :contenteditable="!shareable_link">
                                    <b
                                        v-if="booking.option.product.supplier.service_type_id == 1 && isFirstBookingOfType(day.bookings, booking_index, 1)">
                                        Accommodation —
                                    </b>
                                    <b v-if="booking.option.product.supplier.service_type_id == 2">
                                        Activity —
                                    </b>
                                    <b
                                        v-if="booking.option.product.supplier.service_type_id == 3 && isFirstBookingOfType(day.bookings, booking_index, 3)">
                                        Transportation —
                                    </b>
                                    <!-- <b
                                    v-if="booking.option.product.supplier.service_type_id == 4 && isFirstBookingOfType(day.bookings, booking_index, 4)">
                                    Other:
                                </b> -->
                                </label>
                                <label style="font-weight:600;font-size:20px;" :contenteditable="!shareable_link">
                                    <label v-if="show_supplier_name">
                                        {{ booking.option.product.supplier.supplier_name }}
                                    </label>
                                    <label
                                        v-if="show_supplier_name && show_product_name || show_supplier_name && show_option_name">
                                        — </label>
                                    <label v-if="show_product_name">
                                        {{ booking.option.product.name }}
                                    </label>
                                    <label v-if="show_product_name && show_option_name"> — </label>
                                    <label v-if="show_option_name">
                                        x{{ booking.count }} {{ booking.option.name }}
                                    </label>
                                </label>
                            </v-col>
                            <v-col cols="12" style="font-size:13px;">
                                <v-row v-if="booking.option.product.supplier.service_type_id == 1" class="mt-2"
                                    no-gutters>
                                    <v-col :cols="isMobile() ? 12 : 4">
                                        <b :contenteditable="!shareable_link">Check In: </b>
                                        <label :contenteditable="!shareable_link">
                                            {{ $date(booking.check_in_date).format('MMMM DD') + ', ' +
                                                $date('2024-01-01' + booking.check_in_time).format('h:mm A') }}
                                        </label>
                                    </v-col>
                                    <v-col :cols="isMobile() ? 12 : 4" v-if="isMobile()">
                                        <b :contenteditable="!shareable_link">Check Out: </b>
                                        <label :contenteditable="!shareable_link">
                                            {{ $date(booking.check_out_date).format('MMMM DD') + ', ' +
                                                $date('2024-01-01' + booking.check_out_time).format('h:mm A') }}
                                        </label>
                                    </v-col>
                                    <v-col :cols="isMobile() ? 12 : 8">
                                        <b :contenteditable="!shareable_link">Room: </b>
                                        <label :contenteditable="!shareable_link">
                                            {{ 'x' + booking.count + ', ' + booking.option.product.name }}
                                        </label>
                                    </v-col>
                                    <v-col :cols="isMobile() ? 12 : 4" v-if="!isMobile()">
                                        <b :contenteditable="!shareable_link">Check Out: </b>
                                        <label :contenteditable="!shareable_link">
                                            {{ $date(booking.check_out_date).format('MMMM DD') + ', ' +
                                                $date('2024-01-01' + booking.check_out_time).format('h:mm A') }}
                                        </label>
                                    </v-col>
                                    <v-col :cols="isMobile() ? 12 : 8">
                                        <b :contenteditable="!shareable_link">Location: </b>
                                        <label :contenteditable="!shareable_link">
                                            {{ booking.option.product.supplier.address }}
                                        </label>
                                    </v-col>
                                </v-row>
                                <v-row v-if="booking.option.product.supplier.service_type_id == 3" class="mt-2"
                                    no-gutters>
                                    <v-col :cols="isMobile() ? 6 : 2" :contenteditable="!shareable_link">
                                        <b>Pickup Time: </b>
                                    </v-col>
                                    <v-col :cols="isMobile() ? 6 : 2" :contenteditable="!shareable_link">
                                        {{ $date('2024-01-01' + booking.check_in_time).format('h:mm A') }}
                                    </v-col>
                                    <v-col :cols="isMobile() ? 6 : 2" :contenteditable="!shareable_link">
                                        <b>{{ booking.option.product.type ? booking.option.product.type.name :
                                            'Transporation' }}: </b>
                                    </v-col>
                                    <v-col cols="6" :contenteditable="!shareable_link">
                                        {{ booking.option.product.name }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="booking.option.product.description != null || booking.option.product.supplier.description != null"
                            style="font-size:13px;" class="px-7 pt-3" :contenteditable="!shareable_link" no-gutters>
                            <v-col cols="12">
                                <p v-if="booking.option.product.description != null"
                                    v-html="booking.option.product.description">
                                </p>
                                <p v-else v-html="booking.option.product.supplier.description">
                                </p>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="show_images && (booking.option.product.supplier.id == 3487 || booking.option.product.supplier.id == 3488)"
                            class="px-7 pb-1" style="page-break-inside: avoid;" no-gutters>
                            <v-col cols="12">
                                <v-img v-if="booking.option.base64_image" :src="booking.option.base64_image"
                                    style="height:360px;" eager contain />
                            </v-col>
                        </v-row>
                        <v-row v-else-if="show_images && booking.option.product.supplier.service_type_id != 2" class="px-7 pb-1"
                            style="page-break-inside: avoid;" no-gutters>
                            <v-col v-if="booking.option.product.supplier.base64_image"
                                :cols="booking.option.base64_image && !isMobile() ? '6' : '12'">
                                <v-img :src="booking.option.product.supplier.base64_image" eager :cover="!isMobile()" :contain="isMobile()"
                                    style="width:96%;height:210px;" />
                            </v-col>
                            <v-col v-if="booking.option.base64_image"
                                :cols="booking.option.product.supplier.base64_image && !isMobile() ? '6' : '12'" :class="isMobile() ? 'mt-2' : ''">
                                <v-img :src="booking.option.base64_image" eager :cover="!isMobile()" :contain="isMobile()"
                                    style="width:96%;height:210px;" />
                            </v-col>
                        </v-row>
                        <v-row v-else-if="show_images && booking.option.product.supplier.service_type_id == 2" class="px-7 pb-1"
                            style="page-break-inside: avoid;" no-gutters>
                            <v-col cols="12">
                                <v-img v-if="booking.option.base64_image" :src="booking.option.base64_image" eager
                                    contain style="page-break-inside: avoid;" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: [
        'days', 'itinerary', 'show_supplier_name', 'show_product_name', 'show_option_name', 'shareable_link', 'show_images'
    ],
    methods: {
        isFirstBookingOfType(bookings, current_index, supplier_type_id) {
            const first_activity_index = bookings.findIndex(
                booking => booking.option.product.supplier.service_type_id == supplier_type_id
            )
            return current_index === first_activity_index
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style scoped lang='scss'>
:deep(label),
:deep(b),
:deep(p),
:deep(.v-image) {
    page-break-inside: avoid;
}

:deep(span) {
    font-size: 13px !important;
}

.kavivanar {
    font-family: 'Kavivanar', serif !important;
}

.playfair {
    font-family: 'Playfair', serif !important;
}

.poppins {
    font-family: 'Poppins', sans-serif !important;
}

.lora {
    font-family: 'Lora', serif !important;
}

.inter {
    font-family: 'Inter', sans-serif !important;
}
</style>