import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthenticationState from '../components/login/states/index.js'
import SupplierState from '../components/suppliers/states/index.js'
import ResourcesState from '../components/resources/states/index'
import ContactState from '../components/contacts/states/index.js'
import TaskState from '../components/task/states/index.js'
import AgenciesState from '../components/agencies/states/index.js'
import ItineraryState from '../components/itinerary/states/index.js'
import itineraryV2State from '../components/itinerary/states/itineraryV2.js'
import SupplierPricing from '../components/suppliers/states/supplierpricing.js'
import SupplierRates from '../components/suppliers/states/supplierrates.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: AuthenticationState,
        supplier: SupplierState,
        resources: ResourcesState,
        contact: ContactState,
        task: TaskState,
        agencies: AgenciesState,
        itinerary: ItineraryState,
        itineraryV2: itineraryV2State,
        supplier_pricing: SupplierPricing,
        supplier_rates: SupplierRates,
    },
    plugins: [createPersistedState()]
});