<template>
    <v-dialog :value="delete_booking_dialog" persistent max-width="500">
        <v-card>
            <v-card-title>
                Are you sure you want to delete this booking?
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="deleteBooking()" color="primary" rounded class="px-5">
                    Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'delete_booking_dialog'
    ],
    methods: {
        close() {
            this.$emit('close')
        },
        deleteBooking() {
            this.$emit('deleteBooking')
        },
    },
}
</script>