<template>
    <v-dialog :value="search_supplier_dialog" persistent max-width="1200">
        <v-card>
            <v-card-title class="text-h5">
                Search Suppliers
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
            </v-card-subtitle>
            <v-divider class="mb-5"></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-autocomplete @change="getSuppliers()" :items="towns" v-model="town" label="Town"
                            prepend-inner-icon="mdi-view-list-outline" />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete @change="getSuppliers()" :items="counties" v-model="county" label="County"
                            prepend-inner-icon="mdi-view-list-outline" />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete @change="getSuppliers()" :items="provinces" v-model="province" label="Province"
                            prepend-inner-icon="mdi-view-list-outline" />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete @change="getSuppliers()" :items="countries" v-model="country" label="Country"
                            prepend-inner-icon="mdi-view-list-outline" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete @change="getSuppliers()" :items="get_supplier_types" item-text="name"
                            item-value="id" v-model="supplier_type" label="Supplier Type"
                            prepend-inner-icon="mdi-view-list-outline" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-on:keyup.enter="getSuppliers()" v-model="supplier_name" label="Supplier Name"
                            prepend-inner-icon="mdi-account">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="loading_suppliers" class="text-center">
                <strong>Searching...</strong>
                <br />
                <v-progress-circular :size="120" :width="10" color="primary" indeterminate></v-progress-circular>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="resetSearch()" outlined class="pa-5" rounded>
                    Clear Search
                </v-btn>
                <v-btn @click="getSuppliers()" color="primary" class="pa-5" rounded>
                    Search
                </v-btn>
            </v-card-actions>
            <v-divider />
            <v-card-text class="mt-5" v-if="!loading_suppliers">
                <v-data-table :headers="headers" :items="suppliers" :expanded.sync="expanded" single-expand
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template v-slot:item="{ item }">
                        <tr @click="expandItem(item)" style="cursor:pointer;">
                            <td>
                                <h3>{{ item.supplier_name }}</h3>
                            </td>
                            <td>{{ item.supplier_type.name }}</td>
                            <td>{{ item.town }}</td>
                            <td>{{ item.county }}</td>
                            <td>{{ item.province }}</td>
                            <td>{{ item.country }}</td>
                            <td>{{ item.currency }}</td>
                            <td>
                                <v-btn icon>
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-row class="pa-5" no-gutters>
                                <v-col cols="12" v-if="selected_supplier" class="mb-5">
                                    <div v-html="selected_supplier.warning_note"></div>
                                </v-col>
                                <v-col cols="4">
                                    <b>Products</b>
                                </v-col>
                                <v-col cols="6">
                                    <b>Options</b>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn @click="openCreateBookingDialog()" :disabled="selected_options.length == 0"
                                        color="primary" rounded>
                                        ADD TO BOOKING
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-row v-for="(product, product_index) in item.products.filter(x => !x.is_archived)"
                                        :key="product_index" class="pl-10" no-gutters>
                                        <v-col cols="4">
                                            {{ product.name }}
                                        </v-col>
                                        <v-col cols="8">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-expansion-panels flat v-model="view_rates[product_index]">
                                                        <v-expansion-panel @click="getRates(option, product_index)"
                                                            v-for="(option, option_index) in product.options.filter(x => !x.is_archived)"
                                                            :key="'option' + option_index">
                                                            <v-expansion-panel-header style="padding:0px;">
                                                                <div class="d-flex align-center">
                                                                    <v-checkbox v-model="selected_options"
                                                                        :value="option"
                                                                        style="margin-top:0px;padding-top:20px;"
                                                                        @click.stop :label="option.name" />
                                                                </div>
                                                                <template v-slot:actions>
                                                                    <v-btn class="align-center" style="color:grey;"
                                                                        text>
                                                                        View rates
                                                                        <v-icon class="ml-3">
                                                                            mdi-chevron-down
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                <v-skeleton-loader v-if="loading_rates"
                                                                    type="table-row"></v-skeleton-loader>
                                                                <div v-else-if="selected_option_rates != null && selected_option_rates.length > 0"
                                                                    v-for="(rate_period, rate_period_index) in selected_option_rates"
                                                                    :key="'rate-period' + rate_period_index"
                                                                    class="pl-5">
                                                                    <label>
                                                                        RATES FROM
                                                                        <b>{{ $date(rate_period.start_date)
                                                                            .format('MMMM DD YYYY') }}</b>
                                                                        to
                                                                        <b>{{ $date(rate_period.end_date)
                                                                            .format('MMMM DD YYYY') }}</b>
                                                                    </label>
                                                                    <v-data-table v-if="rate_period.supplierrates"
                                                                        :items="rate_period.supplierrates.filter(x => x.itinerary_id == null)"
                                                                        :headers="rate_headers" hide-default-footer
                                                                        disable-pagination class="rates-table mb-5">
                                                                    </v-data-table>
                                                                </div>
                                                                <div v-else>
                                                                    No rates found
                                                                </div>
                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                </v-col>
                                                <v-col cols="12"
                                                    v-if="product.options.filter(x => !x.is_archived).length == 0">
                                                    <i>No options found</i>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12"
                                            v-if="product_index != item.products.filter(x => !x.is_archived).length - 1">
                                            <hr class="mb-5">
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="item.products.filter(x => !x.is_archived).length == 0">
                                        <v-col cols="12">
                                            <i>No products found</i>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <v-col cols="3" v-for="(product, index) in item.products" :key="index">
                                    <v-card style="min-height:150px;">
                                        <v-card-title>{{ product.name }}</v-card-title>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'search_supplier_dialog', 'selected_day', 'itinerary',
    ],
    data() {
        return {
            loading_suppliers: false,
            supplier_name: null,
            supplier_type: null,
            town: null,
            county: null,
            province: null,
            country: null,
            suppliers: [],
            towns: [],
            counties: [],
            provinces: [],
            countries: [],
            sortDesc: false,
            sortBy: 'created_at',
            headers: [
                { text: 'Supplier Name', width: '70%', sortable: false },
                { text: 'Supplier Type', sortable: false },
                { text: 'Town', sortable: false },
                { text: 'County', sortable: false },
                { text: 'Province', sortable: false },
                { text: 'Country', sortable: false },
                { text: 'Currency', sortable: false },
                { text: '', sortable: false },
            ],
            expanded: [],
            selected_options: [],
            selected_supplier: null,
            loading_rates: false,
            selected_option_rates: null,
            selected_option: null,
            selected_product_index: null,
            view_rates: [],
            rate_headers: [
                { text: 'Day', value: 'day', sortable: false },
                { text: 'Net', value: 'net', sortable: false },
                { text: 'Gross', value: 'gross', sortable: false },
                { text: 'Markup', value: 'markup', sortable: false },
                { text: 'Commission', value: 'commission', sortable: false },
            ],
        }
    },
    mounted() {
        this.getSuppliers()
    },
    async created() {
    },
    computed: {
        ...mapGetters({
            get_supplier_types: 'itineraryV2/get_supplier_types',
            get_product_types: 'itineraryV2/get_product_types',
        })
    },
    methods: {
        async getRates(option, product_index) {
            if (this.selected_product_index != product_index) {
                this.view_rates = []
            }
            if (this.view_rates[product_index] === undefined || this.selected_option.id != option.id) {
                this.loading_rates = true
                this.selected_product_index = product_index
                this.selected_option = option
                let payload = {
                    option_id: option.id,
                }
                await this.$axios.get('v2/suppliers/get_rates_for_option', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.selected_option_rates = data.data
                            this.loading_rates = false
                        }
                    })
            }
        },
        async getSuppliers() {
            let payload = {
                service_type_id: this.supplier_type,
                supplier_name: this.supplier_name,
                town: this.town,
                county: this.county,
                province: this.province,
                country: this.country
            }
            this.loading_suppliers = true
            await this.$axios.get(`v2/suppliers/get_suppliers/${this.itinerary.company.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.suppliers = data.suppliers
                        this.towns = data.towns
                        this.counties = data.counties
                        this.provinces = data.provinces
                        this.countries = data.countries
                        this.loading_suppliers = false
                    }
                })
        },
        resetSearch() {
            this.supplier_name = null
            this.supplier_type = null
            this.town = null
            this.county = null
            this.province = null
            this.country = null
            this.getSuppliers()
        },
        expandItem(item) {
            this.expanded = [item]
            this.selected_options = []
            this.selected_supplier = item
            this.selected_option_rates = null
            this.view_rates = []
        },
        openCreateBookingDialog() {
            let payload = {
                selected_options: this.selected_options,
                selected_supplier: this.selected_supplier,
            }
            this.$emit('openCreateBookingDialog', payload)
        },
        close() {
            this.$emit('close')
            this.selected_supplier = null
            this.selected_options = []
            this.expanded = [{}]
        }
    },
}
</script>

<style scoped lang="scss">
:deep(.rates-table td) {
    height: 20px !important;
}
</style>