<template>
    <div>
        <slot />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user'
        })
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: inherit;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 7px;
}
</style>