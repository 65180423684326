<template>
    <v-dialog :value="create_booking_dialog" persistent max-width="700">
        <v-card v-if="selected_supplier">
            <v-card-title>
                Create booking with {{ selected_supplier.supplier_name }}
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row v-for="(option, index) in selected_options" :key="index">
                    <v-col cols="12">
                        <v-divider class="mb-7" />
                    </v-col>
                    <v-col cols="6" style="align-content:center;">
                        <u>
                            <h3 class="mb-2">{{ option.name }}</h3>
                        </u>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" v-model="option.count" label="Count" />
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="menu1[index]" :close-on-content-click="true" :nudge-right="5"
                            :nudge-bottom="61" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="option.check_in_date" label="Check in date *" readonly
                                    v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="option.check_in_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="option.check_in_time" type="time" label="Check in time">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="menu2[index]" :close-on-content-click="true" :nudge-right="5"
                            :nudge-bottom="61" transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="option.check_out_date" label="Check out date *" readonly
                                    v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="option.check_out_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="option.check_out_time" type="time" label="Check out time">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="createBooking()" color="primary" rounded class="px-5">
                    Create
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'create_booking_dialog', 'itinerary', 'selected_options', 'selected_supplier'
    ],
    data: () => ({
        menu1: [],
        menu2: [],
    }),
    methods: {
        close() {
            this.menu1 = []
            this.menu2 = []
            this.$emit('close')
        },
        createBooking() {
            this.$emit('createBooking', this.selected_options)
        },
    },
}
</script>