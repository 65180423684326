<template>
    <v-container fluid>
        <v-row class="mt-5" justify="center" align="center">
            <v-card elevation="0">
                <v-card-text style="text-align:center;">
                    <div>Please enter your reference number below.</div>
                    <br>
                    <v-text-field label="Your Reference Number" outlined
                        v-model="booking_reference_number"></v-text-field>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 3">Submit & Confirm Booking</v-btn>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 7">Submit & Decline Booking</v-btn>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 4">Submit & Cancel Booking</v-btn>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            booking_reference_number: null,
            itinerary_id: null,
            booking_ids: [],
            status_id: 0,
        }
    },
    mounted() {
        this.getData()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getData() {
            this.itinerary_id = this.$router.currentRoute.params.itinerary_id
            this.booking_ids = this.$router.currentRoute.params.booking_ids.split(',')
            if (this.$router.currentRoute.name == 'Confirm Booking') {
                this.status_id = 3
            }
            if (this.$router.currentRoute.name == 'Decline Booking') {
                this.status_id = 7
            }
            if (this.$router.currentRoute.name == 'Cancel Booking') {
                this.status_id = 4
            }
        },
        async submitReferenceNumber() {
            if (this.booking_reference_number == null) {
                this.$toast.error('Please enter a reference number')
                return
            }
            let payload = {
                booking_ids: this.booking_ids,
                booking_reference_number: this.booking_reference_number,
                status_id: this.status_id,
            }
            console.log(payload)
            await this.$axios.post(`v2/itineraries/supplier_update_booking/${this.itinerary_id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    this.$toast.success(data.message)
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>