<template>
    <v-dialog :value="supplier_details_dialog" persistent max-width="700">
        <v-card v-if="selected_supplier">
            <v-card-title>
                {{ selected_supplier.supplier_name }}
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <b>Email:</b>
                    </v-col>
                    <v-col cols="8">
                        <div>{{ selected_supplier.email }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'supplier_details_dialog', 'selected_supplier'
    ],
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>