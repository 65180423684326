<template>
    <v-dialog :value="update_booking_dialog" persistent max-width="700">
        <v-card v-if="selected_booking">
            <v-card-title>
                Update booking
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-divider class="mb-7" />
                    </v-col>
                    <v-col cols="6" style="align-content:center;">
                        <v-select v-if="product_options" :items="product_options.options"
                            v-model="updated_booking.supplier_product_option_id" item-text="name"
                            item-value="id"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" v-model="updated_booking.count" label="Count" />
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="menu1" :close-on-content-click="true" :nudge-right="5" :nudge-bottom="61"
                            transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="updated_booking.check_in_date" label="Check in date *" readonly
                                    v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="updated_booking.check_in_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_booking.check_in_time" type="time" label="Check in time">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="menu2" :close-on-content-click="true" :nudge-right="5" :nudge-bottom="61"
                            transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="updated_booking.check_out_date" label="Check out date *" readonly
                                    v-bind="attrs" v-on="on" outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="updated_booking.check_out_date" :min="itinerary.start_date"
                                :max="itinerary.end_date + 1"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_booking.check_out_time" type="time" label="Check out time">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="updated_booking.instructions" label="Check in instructions">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="openDeleteBookingDialog()" color="red" text rounded class="px-5">
                    <v-icon>mdi-delete</v-icon>
                    Delete
                </v-btn>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="updateBooking()" color="primary" rounded class="px-5">
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'update_booking_dialog', 'itinerary', 'selected_booking', 'product_options'
    ],
    data() {
        return {
            updated_booking: { ...this.selected_booking },
            menu1: false,
            menu2: false,
        }
    },
    methods: {
        close() {
            this.menu1 = false
            this.menu2 = false
            this.$emit('close')
        },
        updateBooking() {
            this.$emit('updateBooking', this.updated_booking)
        },
        openDeleteBookingDialog() {
            this.$emit('openDeleteBookingDialog')
        }
    },
}
</script>