<template>
    <v-dialog :value="update_company_dialog" width="800" persistent>
        <v-card v-if="selected_company">
            <v-card-title>
                {{ selected_company.company_name }}
                <v-spacer></v-spacer>
                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.company_name" label="Company Name" dense
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.website" label="Website" dense outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.email" label="Reservations Email" dense
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="updated_company.accounts_email" label="Accounts Email" dense
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="logo_file" label="Logo" outlined hide-details dense></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <div v-if="selected_company.company_logo" class="mt-5">
                            <v-img :src="selected_company.company_logo" class="mx-auto" contain max-height="150"
                                max-width="250"></v-img>
                        </div>
                        <label v-else>No image set</label>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="updated_company.cover_title" label="Itinerary Cover Title" dense
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        Primary Color
                        <v-color-picker v-model="updated_company.primary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="4">
                        Secondary Color
                        <v-color-picker v-model="updated_company.secondary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="4">
                        Tertiary Color
                        <v-color-picker v-model="updated_company.tertiary_color" mode="hexa"></v-color-picker>
                    </v-col>
                    <v-col cols="6">
                        <v-file-input v-model="cover_file" label="Itinerary Cover Image" outlined hide-details
                            dense></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <div v-if="selected_company.cover_image" class="mt-5">
                            <v-img :src="selected_company.cover_image" class="mx-auto" contain max-height="150"
                                max-width="250"></v-img>
                        </div>
                        <label v-else>No image set</label>
                    </v-col>
                    <v-col cols="12">
                        Itinerary Cover Description
                        <wysiwyg v-model="updated_company.cover_description" />
                    </v-col>
                    <v-col cols="12">
                        Itinerary Cover Details
                        <wysiwyg v-model="updated_company.cover_details" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" text rounded>Cancel</v-btn>
                <v-btn @click="updateCompany()" color="primary" class="px-5" rounded>
                    Update
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        'update_company_dialog', 'selected_company'
    ],
    data() {
        return {
            updated_company: { ...this.selected_company },
            logo_file: null,
            cover_file: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        updateCompany() {
            let payload = {
                ...this.updated_company,
                logo_file: this.logo_file,
                cover_file: this.cover_file,
            }
            this.$emit('updateCompany', payload)
        },
    }
}
</script>