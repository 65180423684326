import BookingResponseComponent from '../BookingResponseComponent.vue'
import PaymentResponseComponent from '../PaymentResponseComponent.vue'

export default [
    {
        path: '/confirm/:itinerary_id/:booking_ids',
        name: 'Confirm Booking',
        meta: { layout: 'blank' },
        component: BookingResponseComponent,
    },
    {
        path: '/decline/:itinerary_id/:booking_ids',
        name: 'Decline Booking',
        meta: { layout: 'blank' },
        component: BookingResponseComponent,
    },
    {
        path: '/cancel/:itinerary_id/:booking_ids',
        name: 'Cancel Booking',
        meta: { layout: 'blank' },
        component: BookingResponseComponent
    },
    {
        path: '/confirm_payment/:booking_ids',
        name: 'Confirm Payment',
        meta: { layout: 'blank' },
        component: PaymentResponseComponent,
    },
];
